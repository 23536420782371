import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import PlinthLogo from "../PlinthLogo";
import { SITE_URL } from "../../config";

function SidebarHeader({ orgId, funderView, collapsed, onCollapse, link }) {
  let headerLink = link || (funderView ? "/funder/list" : "/dashboard");

  // Common header content for both cases
  const renderHeaderContent = () => (
    <div className="pb-4 pt-4">
      {collapsed ? (
        <button
          className="border border-primary bg-primary ml-2 w-14 h-8 text-white rounded-tl-full rounded-bl-full transition-all duration-300 hover:text-accent active:opacity-75"
          onClick={onCollapse}
        >
          <MenuUnfoldOutlined className="-ml-2" />
        </button>
      ) : (
        <div className="flex flex-row items-center justify-between">
          <Link
            href={headerLink}
            className="flex flex-row items-center space-x-3 pl-4"
          >
            <div className="h-12 w-12 bg-primary rounded-lg flex flex-col justify-center items-center">
              <PlinthLogo className="text-3xl" />
            </div>
            <div className="flex flex-col">
              <img src="/plinth-logo.svg" alt="logo" />
            </div>
          </Link>
          <button
            className="border border-primary bg-primary w-14 h-8 text-white rounded-tl-full rounded-bl-full transition-all duration-300 hover:text-accent active:opacity-75"
            onClick={onCollapse}
          >
            <MenuFoldOutlined className="-ml-2" />
          </button>
        </div>
      )}
    </div>
  );

  if (!orgId) {
    headerLink = `https://${SITE_URL}`;
  }

  return renderHeaderContent();
}

SidebarHeader.propTypes = {
  orgId: PropTypes.string,
  funderView: PropTypes.bool,
  collapsed: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  link: PropTypes.string,
};

SidebarHeader.defaultProps = {
  orgId: undefined,
  funderView: false,
  link: undefined,
};

export default SidebarHeader;
