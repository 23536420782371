import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Dropdown, Menu, Button, Tooltip } from "antd";
import { useRouter } from "next/router";

import { BellOutlined, SwapOutlined } from "@ant-design/icons";

function OrganisationSection({
  name,
  logo,
  multipleAccounts,
  onSwitchAccount,
  className,
}) {
  const router = useRouter();

  return (
    <div
      className={classNames(
        className,
        `sidebar-footer border border-gray-400 border-opacity-50 w-full flex flex-row rounded-md overflow-hidden bg-white`
      )}
    >
      <div className="h-16 w-16 rounded-md flex-shrink-0">
        {logo && (
          <img
            src={logo}
            alt="Organisation Logo"
            className="h-full w-full object-cover"
          />
        )}
      </div>
      <div className="flex flex-col py-2 pl-2 border-l border-gray-400 border-opacity-50 flex-1 justify-center">
        {name && (
          <p className="text-sm cursor-default text-black leading-tight tracking-wide mb-0">
            {name}
          </p>
        )}
      </div>
      <div className="flex flex-col">
        <Dropdown
          menu={{
            items: multipleAccounts
              ?.sort((a, b) => a.Name?.localeCompare(b.Name))
              .map((obj) => ({
                key: obj._id,
                label: obj.Name,
                onClick: () => onSwitchAccount(obj._id),
              })),
          }}
          placement="top"
          className="group"
        >
          <Button className="rounded-none border-0 border-l border-b border-gray-400 border-opacity-50 hover:bg-primary hover:!text-accent group-hover:bg-primary group-hover:!text-accent">
            <SwapOutlined />
          </Button>
        </Dropdown>
        <Tooltip title="Notifications" placement="right">
          <Button
            className="rounded-none border-0 border-l border-gray-400 border-opacity-50 hover:bg-primary hover:!text-accent"
            onClick={() => router.push(`/notifications`)}
          >
            <BellOutlined />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

OrganisationSection.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.string,
  onSwitchAccount: PropTypes.func.isRequired,
  multipleAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      Name: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
};

OrganisationSection.defaultProps = {
  name: "",
  logo: undefined,
  className: undefined,
};

export default OrganisationSection;
