import { Button, Modal, QRCode, message } from "antd";
import {
  multiFactor,
  TotpMultiFactorGenerator,
  getAuth,
  sendEmailVerification,
  onAuthStateChanged,
} from "firebase/auth";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { SITE_NAME } from "../../config";
import TotpCodeInput from "./TotpCodeInput";
import Reauthenticate from "./Reauthenticate";

const TotpSetup = ({ open, onCancel, onComplete }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [totpUri, setTotpUri] = useState("");
  const [secret, setSecret] = useState("");

  const { currentUser } = getAuth();

  // This is a one-time setup to enable TOTP for every account
  const initialSetup = async () => {
    const response = await fetch("/api/auth/totp", {
      method: "POST",
    });

    if (response.ok) {
      alert("TOTP enabled");
    } else {
      alert("Failed to enable TOTP");
    }
  };

  const [uncaughtError, setUncaughtError] = useState(null);

  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const [verifyLinkSent, setVerifyLinkSent] = useState(false);

  const verifyEmail = async () => {
    setVerifyingEmail(true);

    try {
      await sendEmailVerification(currentUser);
      setVerifyLinkSent(true);
    } catch (error) {
      console.error(error);
      setUncaughtError(error);
    }

    setVerifyingEmail(false);
  };

  const [settingUp, setSettingUp] = useState(false);

  const [requiresLogin, setRequiresLogin] = useState(false);

  const setUpTotp = async () => {
    setSettingUp(true);
    try {
      const multiFactorSession = await multiFactor(currentUser).getSession();

      const totpSecret = await TotpMultiFactorGenerator.generateSecret(
        multiFactorSession
      );

      setSettingUp(false);
      setTotpUri(totpSecret.generateQrCodeUrl(currentUser.email, SITE_NAME));

      setSecret(totpSecret);
    } catch (error) {
      setSettingUp(false);

      if (error.code === "auth/unverified-email") {
        verifyEmail();
      } else if (error.code === "auth/requires-recent-login") {
        setRequiresLogin(true);
      } else {
        console.error(error);
        setUncaughtError(error);
      }
    }
  };

  const [emailVerified, setEmailVerified] = useState(
    currentUser?.emailVerified
  );

  useEffect(() => {
    const unsub = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setEmailVerified(user.emailVerified);
      }
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    if (currentUser?.uid && emailVerified && open) {
      setUpTotp();
    } else if (currentUser?.uid && !emailVerified && open && !verifyLinkSent) {
      verifyEmail();
    }
  }, [currentUser, open, emailVerified]);

  const [tryingCode, setTryingCode] = useState(false);

  const handleMultiFactorAssertion = async () => {
    console.log("trying multi factor");
    setTryingCode(true);
    try {
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
        secret,
        verificationCode
      );
      const auth = getAuth().currentUser;
      await multiFactor(auth).enroll(multiFactorAssertion, "totp");
      message.success("Two Factor Authentication set up successfully");

      setTryingCode(false);
      if (onComplete) {
        onComplete();
      } else {
        onCancel();
      }
    } catch (error) {
      console.error(error);
      setTryingCode(false);
      if (error.code === "auth/invalid-verification-code") {
        setUncaughtError({
          message: "Invalid verification code",
        });
      } else {
        console.error(error);
        setUncaughtError(error);
      }
    }
  };

  useEffect(() => {
    if (verificationCode.length === 6) {
      handleMultiFactorAssertion();
    }
  }, [verificationCode]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closable={!!onCancel}
      style={{ top: 40 }}
      destroyOnClose
      footer={verifyLinkSent || requiresLogin ? null : undefined}
    >
      <h2 className="text-lg font-medium">Set up Two Factor Authentication</h2>
      {requiresLogin && (
        <Reauthenticate
          onFinish={() => {
            setRequiresLogin(false);
            setUpTotp();
          }}
        />
      )}
      {verifyLinkSent ? (
        <div>
          <p>
            You&apos;ll need to verify your email address first before you can
            set up 2 Factor Authentication. We&apos;ve sent a link to your email
            address.
          </p>
          <div className="flex space-x-2 mt-4 justify-end">
            <Button type="link" onClick={verifyEmail}>
              Re-send email
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setVerifyLinkSent(false);
                setEmailVerified(true);
              }}
            >
              I&apos;ve verified my email
            </Button>
          </div>
        </div>
      ) : (
        !requiresLogin && (
          <p className="text-sm text-gray-700">
            You&apos;ll need to scan the QR code below with an authenticator
            app. We recommend using{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US"
              target="_blank"
              rel="noopener noreferrer"
              className="border-b border-accent text-primary hover:border-primary"
            >
              Google Authenticator
            </a>
            ,{" "}
            <a
              href="https://www.microsoft.com/en-gb/security/mobile-authenticator-app"
              target="_blank"
              rel="noopener noreferrer"
              className="border-b border-accent text-primary hover:border-primary"
            >
              Microsoft Authenticator
            </a>{" "}
            or{" "}
            <a
              href="https://authy.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="border-b border-accent text-primary hover:border-primary"
            >
              Authy
            </a>
            .
          </p>
        )
      )}

      {uncaughtError && (
        <div className="bg-red-50 border-red-500 border-l-4 p-4 mt-4">
          <p className="text-red-700">An error occurred:</p>
          <pre className="text-sm">{uncaughtError.message}</pre>
        </div>
      )}

      {settingUp && (
        <div className="flex flex-col gap-4 m-4">
          <LoadingOutlined />
        </div>
      )}

      {totpUri && (
        <div className="flex flex-col items-center justify-center space-y-4 my-4">
          <QRCode value={totpUri} />

          <p>Code: {secret?.secretKey}</p>

          <p className="text-sm text-gray-700">
            Type the verification code from your authenticator app below to
            finish setting up Two Factor Authentication.
          </p>

          <TotpCodeInput onFinish={setVerificationCode} loading={tryingCode} />
        </div>
      )}
    </Modal>
  );
};

TotpSetup.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
};

TotpSetup.defaultProps = {
  open: false,
  onCancel: null,
  onComplete: null,
};

export default TotpSetup;
